@import url(https://fonts.googleapis.com/css?family=Patrick+Hand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #59C3C3;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* * {
  margin: 0;
  padding: 0;
} */


.App {
  text-align: center;
  background-color: #59C3C3;
  height: 100%;
  width: 100%;
  padding-top: 3%;
  padding-bottom: 5%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.form-control{
  width: 50px;
}
.loginDiv{
  margin-top: 12%;
  padding-top: 2%;
  padding-bottom: 10%; 
  position: absolute;
  width: 38%;
  height: 60%;
  background: #016FB9;
  border-radius: 80px;
  left: 30%;
  top: 10%;
}
.loginHeading, .h1PendingStories, .h1ApprovedStories{
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 48px;
line-height: 150px;
color: #EEEEEE;
}
label{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 36px;
/* line-height: 10%; */
color: #EEEEEE;

}
.emailSpacing{
  margin-right: 12%;
}
.passwordSpacing{
  margin-right: 2%;
}
input{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 36px;
/* line-height: 19px; */
border-style: none;
border-bottom:  1px solid #C4C4C4;
width: 40%;
}
input[type="text"] {

  background-color : #016FB9; 
  color: white;

}
input::-webkit-input-placeholder { 
  color: white; 
  padding-left: 6%;
}
input::-moz-placeholder { 
  color: white; 
  padding-left: 6%;
}
input:-ms-input-placeholder { 
  color: white; 
  padding-left: 6%;
}
input::-ms-input-placeholder { 
  color: white; 
  padding-left: 6%;
}
input::placeholder { 
  color: white; 
  padding-left: 6%;
}
#inputSpacing{
  margin-top: 6%;
  margin-bottom: 10%;
}
button{
  background: #31B901;
  border-radius: 30px;
  font-family: Roboto;
  padding-top: 3% ;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
font-style: normal; 
font-weight: normal;
font-size: 54px;
line-height: 21px;

color: #EEEEEE;
}
.pendingStoriesDiv{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
 
}
.pendingStoryDiv{
  padding-top: 2%;
  padding-bottom: 3%; 
  margin-bottom: 4%;
  margin-left: 10%;
  width: 30%;
  background: #016FB9;
  border-radius: 50px;
  color: white;
  font-size: 18px;
  
}
.pendingStoriesButton{
  border-radius: 20px;
  font-family: Roboto;
  padding-top: 3% ;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
font-style: normal; 
font-weight: normal;
font-size: 16px;
line-height: 21px;

}
.buttonSpacing{
  margin-left: 30%;
}
.approvedStoryDiv{
  background: #016FB9;
  border-radius: 80px;
  font-size: 28px;
  width: 60%;
  margin: 5% auto;
  padding: 2%;
  color: white;
}
nav{
  margin-top: 0 auto;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 1%;

}
a{
  font-size: 20px;
  padding-right: 12.5%;
  padding-left: 12.5%;
  font-weight: bolder;
  text-transform: uppercase;
}

.h1login{
  color: white;
}
.navi{
  margin-top: 0 auto;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 1%;


}
.stories-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
   
  
}
.card{
  width: 45%;
  margin-bottom: 2%;
  padding: 5%;
  /* background: #016FB9; */
  /* background: #59C3C3; */
}
.stories{
  /* color: white; */
  /* background: #016FB9; */
  padding-left: 5%;
  padding-right: 5%;
}
.stories-container{

}
.story{
  /* background: #016FB9; */
  border-radius: 6%;
}
.story a{
  /* color: #59C3C3; */
}
.story a:hover{
  /* color: #31B901; */
}
